
.option-container {
    background: #FFFFFF;
    border: 2px solid #D6D6D6;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 2147483647 !important;
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: 0;
}

.item {
    position: relative;
    padding: 3px 1em 3px 0.4em;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #29333C;
}

.item:hover, .item:focus {
    background: #CEE7FF;
}