.App {
  background-color: transparent;
}

.App {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  background-image: url(../src/assets/background/background.svg);
  background-repeat: no-repeat;
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  // height: 100vh;
  background-color: transparent;
  letter-spacing: 0.5px;
}

html,
body {
  margin: 0;
}
