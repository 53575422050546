body {
  margin: 0;
  font-family: "Oxygen", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../src/assets/background/background.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.normalRow {
  background-color: transparent;
}

#actions {
  opacity: 0;
}

.normalRow:hover #actions {
  opacity: 1;
}

.highlightedRow:hover #actions {
  opacity: 1;
}

.middle-page {
  height: 100vh;
  width: 100vw;
}

.transition-height {
  transition: all 1s !important;
}

[role="listbox"] {
  max-height: 300px !important;
}

[role="combobox"] {
  min-width: 100px !important;
}

#client-info {
  opacity: 0;
  /* transition: opacity 0.2s ease-in-out; */
  pointer-events: none;
  position: absolute;
  margin: 20px;
  cursor: pointer;
}

#client-info-wrapper:hover #client-info {
  position: absolute;
  margin: 20px;
  opacity: 1;
  z-index: 1000001;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  pointer-events: all;
}

#client-info-wrapper:hover .client-info-wrapper {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}

.btn-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 55px;
  height: 55px;
  border: 2px solid #000000;
  background-color: transparent;
  position: relative;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  opacity: 1;
  z-index: 10000001;
  transition: opacity 0.2s ease-in-out;
}

.btn-circle-icon-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 2px solid #000000;
  background-color: transparent;
  position: relative;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  opacity: 1;
  z-index: 10000001;
  transition: opacity 0.2s ease-in-out;
}

.icon-container {
  opacity: 0;
}
